import SimpleContainer from "@/views/SimpleContainer";
const BilanDesGens = () => import("@/views/bilan/BilanDesGens");
const BilanDesGensItem = () => import("@/views/bilan/BilanDesGensItem");
const BilanDesTerritoiresViewHandler = () => import("@/views/bilan/BilanDesTerritoiresViewHandler");
const BilanDesTerritoiresETCLD = () => import("@/views/bilan/BilanDesTerritoiresETCLD");

const routesBilan = [
  {
    path: "/bilan/",
    component: SimpleContainer,
    meta: {
      title: "Contribuez au Bilan",
      icon: "mdi-bullhorn-variant-outline",
      displayInMenu: true,
      disabled: true,
      permissions: ["bilan_gens:list", "bilan_territoire:read"],
    },
    children: [
      {
        path: "/bilan/gens",
        name: "bilan-des-gens",
        component: BilanDesGens,
        meta: {
          title: "Bilan des gens",
          displayInMenu: true,
          notificationTriggers: ["bilan.bilan_des_gens_available_menu"],
          permissions: ["bilan_gens:list"],
        },
      },
      {
        path: "/bilan/gens/create",
        name: "bilan-des-gens-create",
        component: BilanDesGensItem,
        meta: {
          title: "Dépôt d'un témoignage",
          groups: ["bilan_gens:write"],
        },
      },
      {
        path: "/bilan/gens/:id",
        name: "bilan-des-gens-item",
        component: BilanDesGensItem,
        meta: {
          title: "Dépôt d'un témoignage",
          permissions: ["bilan_gens:read"],
        },
      },
      {
        path: "/bilan/territoires",
        name: "bilan-des-territoires",
        component: BilanDesTerritoiresViewHandler,
        meta: {
          title: "Bilan des territoires",
          displayInMenu: true,
          notificationTriggers: ["bilan.bilan_des_territoires_available_menu"],
          permissions: ["bilan_territoire:read"],
        },
      },
      {
        path: "/bilan/territoires/:id",
        name: "etcld-bilan-des-territoires-item",
        component: BilanDesTerritoiresETCLD,
        meta: {
          groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur"],
        },
      },
    ],
  },
];
export default [...routesBilan];
